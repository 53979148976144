import { Box, Text, Link } from 'rebass';
import { Icon, Footnote } from "../../../../src/components";
import OrigamiSquirrelFilled from "../../../../src/images/origami-squirrel-filled.svg";
import OrigamiSquirrelOutline from "../../../../src/images/origami-squirrel-outline.svg";
import React from 'react';
export default {
  Box,
  Text,
  Link,
  Icon,
  Footnote,
  OrigamiSquirrelFilled,
  OrigamiSquirrelOutline,
  React
};